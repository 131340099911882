import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import _ from "lodash";
import toast from "react-hot-toast";
import { AdminService, WLOCreditAPIService } from "../../../services";
import React from "react";
import Moment from 'moment';
import { terms, typeCredit } from "../../../__mocks__/data";
import { useRequestContext } from "../../../hooks/useRequestContext";
import ClockLoader from "react-spinners/ClockLoader";
import ParseNumberThousandMedium from "../../shared/Utilities/ParseNumberThousandMedium";
import ParseNumberSmaller from "../../shared/Utilities/ParseNumberSmaller";
import ParseNumberSmallertext from "../../shared/Utilities/ParseNumberSmallerText";
import NumberFormat from 'react-number-format';
import { Edit, SaveAsSharp } from "@mui/icons-material";
import { stringify } from "querystring";
interface FormCreditSimulatorProp {
    errors?: any,
    values?: any,
}

export function FormCreditSimulator(props: FormCreditSimulatorProp) {
    const { request, setRequest } = useRequestContext();
    const { errors, values } = props;
    const intl = useIntl();
    const [valueQuote, setValueQuote] = React.useState(0);
    const [valueToFinance, setValueToFinance] = React.useState(0);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [isLoadingSimulator, setIsSimulator] = useState(false);
    const [isLoadingEmail, setIsEmail] = useState(false);
    const [listCredit, setListcredit] = useState([]);
    const [listPeriodos, setPeriodos] = useState([] as any);
    const [valorVehiculo, setvalorVehiculo] = React.useState("");
    const [editInfoVehiculo, setEditInfoVehiculo] = useState(false);
    const [one] = useState(false);
    useEffect(() => {
        getValueListCreditos();
        setvalorVehiculo(request.priceProduct!);
        getPoliza();
    }, [one])

    useEffect(() => {
        values.Pws_Val_cuoini = '0';
        console.log("si me ejecuto")
        console.log(values)
    
    }, [values]);

     const getPoliza= async ()=>{
        if(sessionStorage.getItem('datos_Poliza')){
            const poliza = JSON.parse(sessionStorage.getItem('datos_Poliza')!);
            const plazoPoliza = sessionStorage.getItem('plazo_poliza');
            console.log(poliza);
            values.Pws_Val_monto= poliza.price;
            values.Pws_Correo =poliza.email;
            values.pws_nro_cuotas= poliza.cuotas;
            values.valorCuota= poliza.valorCuota;         
            values.Pws_Val_cuoini = poliza.cuotaInicial;
        }
        if(sessionStorage.getItem('idLinea')){
            values.pws_cod_credi= sessionStorage.getItem('idLinea');
        }
    }
    const getValueListCreditos = async () => {
        const valor = await AdminService.getlineascredito();
        if (valor.payload.data.ListLineaCredito.Datos_LineasCredito.length > 0) {
            const list = valor.payload.data.ListLineaCredito.Datos_LineasCredito.reduce((acc: any[], item: any) => {
                const tipoProducto = sessionStorage.getItem('CodProducto')!;
                
                if (
                    (tipoProducto == '2' && (item.s_codigo == '1' || item.s_codigo == '2')) ||
                    (tipoProducto == '1' && (item.s_codigo == '3' || item.s_codigo == '4'))
                ) {
                    console.log("ITEM", item);
                    acc.push(item); // Agregamos solo los elementos que cumplen las condiciones
                }
                
                return acc; // Retornamos el acumulador actualizado
            }, []);
            setListcredit(list);
        }
        const valor2 = await AdminService.getPeriodosId("1");
        console.log(valor2);
        console.log(valor2.length);
        
        if (valor2.payload.data.ListPerido.Datos_Periodo.length > 0
            && valor2.payload.data.ListPerido != null && valor2.payload.data.ListPerido!) {
                setPeriodos(valor2.payload.data.ListPerido.Datos_Periodo);
                if(sessionStorage.getItem('datos_Poliza')){
                    const plazoPoliza = sessionStorage.getItem('plazo_poliza');
                    const itemEncontrado = await valor2.payload.data.ListPerido.Datos_Periodo.find((item: any) => item.s_descripcion === plazoPoliza);
                    values.Pws_Val_pla= itemEncontrado.s_descripcion            
                }
        } else {
            let periodos = [
                { s_codigo: "1", s_descripcion: "Mensual" }
                // { s_codigo: "2", s_descripcion: "Quincenal" },
                // { s_codigo: "3", s_descripcion: "Anual" },
                // { s_codigo: "4", s_descripcion: "Diaria" },
                // { s_codigo: "5", s_descripcion: "Semestral" },
                // { s_codigo: "6", s_descripcion: "Trimestral" },
                // { s_codigo: "7", s_descripcion: "Bimestral" }
            ]
            setPeriodos(periodos)
        }
    }
    const handleSendEmail = async (valorcuota: string, valorinicial: string) => {
        setIsEmail(true);
        setIsLoadingInfo(true);
        if (_.isEmpty(values.pws_cod_credi!) || _.isEmpty(values.Pws_Val_pla!) || _.isEmpty(values.Pws_Val_cuoini!) || _.isEmpty(values.pws_nro_cuotas!) || _.isEmpty(values.Pws_Correo!) || _.isEmpty(values.Pws_Identificacion!)) {
            toast.error('Debe indicar los valores [Correo, Opción de Crédito, Plazo, Valor cuota inicial, Ingrese una cedula correcta, Nro. Cuotas] para poder calcular ');
        } else {
            try {

                const emailInfo = {
                    dateSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
                    product: request.nameProduct!,
                    price: values.Pws_Val_monto,
                    cuotas: values.pws_nro_cuotas,
                    cuotaInicial: valorinicial,
                    valorCuota: valorcuota,
                    email: values.Pws_Correo!,
                }
                sessionStorage.setItem('id_producto', JSON.stringify(request.nameProduct));
                console.log(emailInfo)
                const resultEnvioCorreo = await WLOCreditAPIService.sendEmailCotizacion(emailInfo);
                if (resultEnvioCorreo) {
                    toast.success('Se ha enviado con exito el email con la cotización');
                    setIsEmail(false);
                    setIsLoadingInfo(false);
                }
            } catch (error) {
                setIsSimulator(true);
                toast.error('En este momento no podemos atender su solicitud');
                setTimeout(() => {
                    setIsSimulator(false);
                }, 1000);
            }
        }
    }
    //---------------CUOTAAAA------------------------------
    const handleCalculatedQuote = async (e: any) => {
        e.preventDefault();
        setIsSimulator(true);
       
        let cuotaFormated;
        let cuotaNumberFormated;
        let plazo: string;
        if (values) {
            if (_.isEmpty(values.pws_cod_credi!) || _.isEmpty(values.Pws_Val_pla!) || _.isEmpty(values.Pws_Val_cuoini!) || _.isEmpty(values.Pws_Identificacion) || _.isEmpty(values.pws_nro_cuotas!)) {
                toast.error('Debe indicar los valores [Opción de Crédito, Plazo, Valor cuota inicial, Nro de Cuotas e Identificación] para poder calcular');
                setIsSimulator(false);
            } else {
                try {
                    const montoVehiculo = values.Pws_Val_monto.replaceAll(',', '');
                    cuotaFormated = values.Pws_Val_cuoini.replaceAll(',', '');
                    cuotaNumberFormated = values.pws_nro_cuotas.replaceAll(',', '');
                    if (parseFloat(cuotaFormated) > parseFloat(montoVehiculo!)) {
                        
                        toast.error('La cuota inicial debe ser menor al valor a solicitar');
                        return;
                    }
                    if (parseFloat(cuotaNumberFormated) > 999) {
                        toast.error('El nro de cuota no puede ser mayor a 999');
                    }
                    else {
                        plazo = values.Pws_Val_pla;
                        const valorToFinanciar = String(parseFloat(montoVehiculo!) - parseFloat(cuotaFormated));
                        console.log(montoVehiculo)
                        console.log(cuotaFormated)
                        console.log(valorToFinanciar)
                        let infoEnviadaSimulador = {
                            pws_monto: valorToFinanciar!,
                            pws_nro_cuotas: values.pws_nro_cuotas!,
                            pws_fecha_sol: new Date().toDateString(),
                            pws_cod_credi: values.pws_cod_credi!,
                            pws_form_plazo: plazo,
                            pws_identific: values.Pws_Identificacion,
                            pws_form_periodic: plazo
                        };
                        console.log("INFO ENVIADA AL SERVICE CYGNUS", infoEnviadaSimulador);

                        let simulador = await WLOCreditAPIService.getSimulador({
                            pws_monto: valorToFinanciar!,
                            pws_nro_cuotas: values.pws_nro_cuotas!,
                            pws_fecha_sol: new Date().toDateString(),
                            pws_cod_credi: values.pws_cod_credi!,
                            pws_form_plazo: plazo,
                            pws_identific: values.Pws_Identificacion,
                            pws_form_periodic: plazo
                        });

                        if (!_.isEmpty(simulador.payload) && !_.isEmpty(simulador.payload.data)) {
                            const valorQuote = simulador.payload.data.R_Val_cuota;
                            console.log("VALOR CUOTA!!!", valorQuote)
                            values.Pws_Val_cuo = valorQuote;
                            values.Pws_Val_finan = valorToFinanciar;
                            setValueToFinance(parseFloat(valorToFinanciar));
                            setValueQuote(valorQuote);
                            const infoCredit = simulador;
                            setRequest({
                                ...request,
                                valueOfInicialQuote: values.Pws_Val_cuoini!,
                                valueOfQuote: valorQuote,
                                valueToFinance: valorToFinanciar.toString(),
                                simulator: infoCredit,
                                isRotativo: Boolean(listCredit.filter((valorflitro: any) => valorflitro.s_codigo == values.pws_cod_credi)[0]['s_rotativo'])
                            });
                            toast.success('Se ha generado un nuevo calculo de cuota');
                            handleSendEmail(valorQuote, String(values.Pws_Val_cuoini!))
                            setIsSimulator(false);
                        }
                    }
                } catch (e) {
                    setIsSimulator(true);
                    toast.error('En este momento no podemos atender su solicitud');
                    setTimeout(() => {
                        setIsSimulator(false);
                    }, 4000);
                }

            }
        }
    }

    const cambiarUpdat = () => {
        console.log(values.Pws_Val_vehiculo);
        console.log(values.Pws_Val_vehiculo.toString());
        setvalorVehiculo(values.Pws_Val_vehiculo.toString());
        toast.success('Valor del vehiculo actualizado');
        setEditInfoVehiculo(false)
    }



    return (
        <div className={'row'}>
            <ClockLoader id='spinner' color={"#d72222"} loading={isLoadingSimulator} size={100} />
            <ClockLoader id='spinner' color={"#d72222"} loading={isLoadingEmail} size={100} />
            <div className="d-none d-md-block d-lg-none">
                <img style={{ height: "400px", width: "400px" }} src={request.imagemoto} alt={''} />
            </div>
            <br />
            <div
                className={"title-large-bold"}>{intl.formatMessage({ id: "form_credit_information_promotion_title" })}</div>
            {/* <div id={"model-product"} className={"title-large-red"}>{request.nameProduct}</div> */}
            {/* <div className={'flex-container-form-know-you'}>   
                <div className={"flex-container-form-know-you-item-right flex-grow-8"}>
                    <div id={"price-product"} className={"title-large"}>
                        <NumberFormat value={parseInt(valorVehiculo!)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </div>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-4"}>
                    <div onClick={()=>{ setEditInfoVehiculo(true)}}> 
                        <Edit />
                    </div>
                </div>
            </div> */}

            {/* {editInfoVehiculo ? <>
                <div className={'flex-container-form-know-you'}>          
                <div className={"flex-container-form-know-you-item-right flex-grow-6"}>
                    <Field id={"Pws_Val_vehiculo"}
                        name={"Pws_Val_vehiculo"}
                        // component={ParseNumberThousandMedium}
                        placeholder={intl.formatMessage({ id: "placeholder_initial_quote_symbol" })}
                        className={`${errors.Pws_Val_vehiculo ? 'form-know-you-item-input-medium input-error' : 'form-know-you-item-input-medium'}`}
                        disabled={isLoadingInfo}
                    />
                </div>

                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <div onClick={cambiarUpdat}> 
                        <SaveAsSharp/>
                    </div>
                </div>
            </div>
            </> : <>
            </>
            } */}

            {/* <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-12"}>
                    <div className={"form-know-you-item-note"}>
                        {intl.formatMessage({ id: "form_credit_information_promotion_subtitle" })}
                    </div>
                </div>
            </div> */}
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-right flex-grow-4"}>
                    <div className={"subtitle-medium"}>{intl.formatMessage({ id: "option_credit" })}</div>
                    <Field as="select"
                        id={"pws_cod_credi"}
                        className={`${errors.pws_cod_credi ? 'form-know-you-item-input-md input-error' : 'form-know-you-item-input-md'}`}
                        name="pws_cod_credi"
                        disabled={isLoadingSimulator}
                    >
                        <option>{intl.formatMessage({ id: "option_credit" })}</option>
                        {listCredit && listCredit.map((item: any, key: number) => {
                            return (
                                <option key={key} value={item.s_codigo}>{item.s_nombre}</option>
                            )
                        })}
                    </Field>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-4"}>
                    <div className={"label-input-right"}>{intl.formatMessage({ id: "value_to_request" })}</div>
                    <Field id={"Pws_Val_monto"}
                        name={"Pws_Val_monto"}
                        component={ParseNumberThousandMedium}
                        placeholder={intl.formatMessage({ id: "placeholder_initial_quote_symbol" })}
                        className={`${errors.Pws_Val_cuoini ? 'form-know-you-item-input-medium input-error' : 'form-know-you-item-input-medium'}`}
                        disabled={isLoadingInfo}
                    />
                </div>
            </div>
            {/* <div className="col-2" />
            <div className="col-8">
                <div className={"subtitle-medium"}>{intl.formatMessage({ id: "form_credit_information_selection" })}</div>
            </div>
            <div className="col-2" /> */}
            <div className={'flex-container-form-know-you'}>
               {/*  <div className={"flex-container-form-know-you-item-right flex-grow-4"} style={{ display: "none" }}>
                    <div className={"label-input-right"}>{intl.formatMessage({ id: "initial_quote_value" })}</div>
                    <Field id={"Pws_Val_cuoini"}
                        name={"Pws_Val_cuoini"}
                        component={ParseNumberThousandMedium}
                        placeholder={intl.formatMessage({ id: "placeholder_initial_quote_symbol" })}
                        className={`${errors.Pws_Val_cuoini ? 'form-know-you-item-input-medium input-error' : 'form-know-you-item-input-medium'}`}
                        disabled={isLoadingInfo}
                    />
                </div> */}

                <div className={"flex-container-form-know-you-item-left flex-grow-4 input-plazo"}>
                    <div className={"label-input-left"}>{intl.formatMessage({ id: "term" })}</div>
                    <Field as="select"
                        className={`${errors.Pws_Val_pla ? 'form-know-you-item-input-medium input-error' : 'form-know-you-item-input-medium'}`}
                        name="Pws_Val_pla"
                        disabled={isLoadingSimulator}
                    >
                        <option>{intl.formatMessage({ id: "term" })}</option>
                        {listPeriodos && listPeriodos.map((item: any, key: number) => {
                            return (
                                <option key={key} value={item.s_descripcion}>{item.s_descripcion}</option>
                            )
                        })}
                    </Field>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-4"}>
                    <div className={"label-input-left"}>{intl.formatMessage({ id: "nro_quotes" })}</div>
                    <Field id={"pws_nro_cuotas"}
                        name={"pws_nro_cuotas"}
                        component={ParseNumberSmaller}
                        placeholder={intl.formatMessage({ id: "nro_quotes" })}
                        className={`${errors.pws_nro_cuotas ? 'form-know-you-item-input-medium display-flex-cuota input-error' : 'form-know-you-item-input-medium display-flex-cuota'}`}
                        disabled={isLoadingSimulator}
                    />
                </div>
            </div>
            <div className={'flex-container-form-know-you '}>
                <div className={"flex-container-form-know-you-item-right   flex-grow-6"}>
                    <div className={"label-input-right text-center"} style={{ "paddingRight": "0px" }}>{intl.formatMessage({ id: "value_to_finance" })}</div>
                    <div className="roundNumberFormat text-center">
                        <NumberFormat value={parseInt(request.valueToFinance!)} displayType={'text'} thousandSeparator={true} prefix={'$'} />

                    </div>
                </div>
                <div className={"flex-container-form-know-you-item-left flex-grow-6"}>
                    <div className={"label-input-left text-center"} style={{ "paddingLeft": "0px" }}>{intl.formatMessage({ id: "value_of_quote" })}</div>
                    <div className="roundNumberFormat text-center">
                        <NumberFormat value={parseInt(request.valueOfQuote!)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </div>

                </div>

            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-grow-2"}></div>
                <div className={"flex-container-form-know-you-item-right flex-grow-8"}>
                    <div className={"form-know-you-item-button"} onClick={handleCalculatedQuote}>
                        {intl.formatMessage({ id: "button_calculate_quote" })}
                    </div>
                </div>
                <div className={"flex-grow-2"}></div>
            </div>
            <div className={'flex-container-form-know-you'}>
                <div className={"flex-container-form-know-you-item-center flex-grow-6"}>
                    <div className={"subtitle-medium"}>
                        {intl.formatMessage({ id: "form_credit_information_note" })}
                    </div>
                </div>
            </div>
        </div>
    )
}