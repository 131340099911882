import React, { useEffect, useState } from 'react';
import './Policies.scss';
import familia from '../../assets/images/familia.png';
import { ModalConfirmPolicies } from './ConfirmPolicies/ConfirmPolicies';
import { Popup } from '../../components/shared/Popup';
import { ANIPersona, CygnusPersona } from '../../context/RequestContext';
import { AdminService, WLOCreditAPIService } from '../../services';
import { useLoginContext } from '../../hooks/useLoginContext';
import { useRequestContext } from '../../hooks/useRequestContext';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { typeIdentification } from '../../__mocks__/data';
import Moment from 'moment';


interface PolicyFormData {
  documentType: number;
  Pws_Identificacion: string;
  Pws_Val_pla: string;
  Pws_Val_cuoini: string;
  periodicity: string;
  Pws_Val_Pol: string;
  interestRate: string;
  Pws_Val_finan: string;
  email: string;
  fullName: string;
  plan:string;
  tipoLinea: string;
}

export const Policies: React.FC = () => {
  const {user} = useLoginContext();
  const {request, setRequest} = useRequestContext();
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [plan, setPlan] = useState(false);
  const [numDoc, setNumDoc] = useState();
  const [numTipoDoc, setNumTipoDoc] = useState();
  const [popup, setPopup] = useState("close");
  const [isLoadingSimulator, setIsSimulator] = useState(false);
  const [valorVehiculo, setvalorVehiculo] = React.useState("");
  const [valueQuote, setValueQuote] = React.useState("");
  const [valueToFinance, setValueToFinance] = React.useState(0);
  const [isLoadingEmail, setIsEmail] = useState(false);
  const [listCredit, setListcredit] = useState([]);
  const [tiposIdent, setTiposIdent] = useState([]);
  const [listPeriodos, setPeriodos] = useState([] as any);
const [simulatorCuotas, setSimulatorCuotas] = useState<any[]>([]);
  const [one] = useState(false);
  const [formattedPolValue, setFormattedPolValue] = useState('');
const [formattedInitialQuote, setFormattedInitialQuote] = useState('');
  const [formData, setFormData] = React.useState<PolicyFormData>({
    documentType: 0,
    Pws_Identificacion: '',
    Pws_Val_pla: '',
    Pws_Val_cuoini:'0',
    periodicity: '',
    Pws_Val_Pol: '',
    interestRate: '25',
    Pws_Val_finan: '',
    email: '',
    fullName: '',
    plan:'',
    tipoLinea: '',
  });

  useEffect(() => {
  /*   const datosPersonaString = sessionStorage.getItem("lineasCred"); */
    const datosPersonaString = sessionStorage.getItem("personaValidada");
    const idLinea =  sessionStorage.getItem("idLinea");
    setTiposIdent(JSON.parse(sessionStorage.getItem("tiposIden")!));
    const datosPersona = JSON.parse(datosPersonaString!);
    setNumDoc(datosPersona.numIdenVal);
    const numTipoDoc = datosPersona.tipIdenVal;
    handleSearch()
    setFormData({...formData, Pws_Identificacion: datosPersona.numIdenVal, documentType: numTipoDoc, fullName: datosPersona.nombresVal, tipoLinea: idLinea!});
  }, []);

  const handleSearch = async () => {

    try {
      let consultaANI = false;
      const person = await WLOCreditAPIService.consultaSolicitudCygnus({
          Pws_Tip_Identificacion: numTipoDoc!,
          Pws_Identificacion: numDoc!
      });      
      console.log(person);
      
      // if (!_.isEmpty(person.payload)) {
      //   let Pws_Apellidos1;
      //   let Pws_Apellidos2;
      //   let Pws_Nombres;
      //   let Pws_Fec_expe;
      //     if (person.payload.result == "1") {
      //         const pCygnus = person.payload.data.datos_persona.Datos_persona[0] as CygnusPersona;
      //         Pws_Apellidos1=pCygnus.Pws_Apellidos1;
      //         Pws_Apellidos2=pCygnus.Pws_Apellidos2;
      //         Pws_Nombres=pCygnus.Pws_Nombres;
      //         setRequest({...request, personCygnus: pCygnus, searchANI: false, errorConsulta: false});
      //     } else {
      //         consultaANI = true;
      //         const personANI = await WLOCreditAPIService.consultaSolicitudANI({
      //             Pws_Tip_Identificacion: numTipoDoc!,
      //             Pws_Identificacion: numDoc!
      //         });
      //         console.log(personANI);
              
              
      //         const pANI = personANI as ANIPersona;
      //         const fecha = personANI.fechaExpedicion.split('/');
      //         Pws_Apellidos1=pANI.primerApellido;
      //         Pws_Apellidos2=pANI.segundoApellido;
      //         Pws_Nombres=pANI.primerNombre+" "+pANI.segundoNombre;
      //         Pws_Fec_expe= fecha[2]+'-'+fecha[1]+'-'+fecha[0]
      //         pANI.fechaExpedicion = fecha[2]+'-'+fecha[1]+'-'+fecha[0]
      //         setIsLoadingInfo(false);
      //         setRequest({...request, personANI: pANI, searchANI: true, errorConsulta: false});
      //         sessionStorage.setItem('person', JSON.stringify(person));
      //     }
      // }else{
      //     toast.error('En estos momentos no podemos atender su solicitud',{
      //         duration:5000,
      //     });
          
      // }
    } catch (e) {
      const error = e as any;
      if (!_.isEmpty(error.response.data)) {
          setRequest({...request, errorConsulta: true});
          const menssage = error.response.data as any;
          toast.error("El documento de identificación no existe.",{
              duration:5000,
          });
          setTimeout(() => {
              setIsLoadingInfo(false);
          }, 5000);
      } else {
          setRequest({...request, errorConsulta: true});
          toast.error('En estos momentos no podemos atender su solicitud',{
              duration:5000,
          });
          setTimeout(() => {
              setIsLoadingInfo(false);
          }, 5000);
          
      }
  }
  }

  const formatNumber = (value: string): string => {
    console.log(value);
    
    // Eliminar cualquier carácter que no sea un número
    const cleanNumber = value.replace(/\D/g, '');
    console.log(cleanNumber);
    
    if (!cleanNumber) return ''; // Si no hay números, retorna una cadena vacía
    
    // Formatear el número sin convertirlo a entero
    return cleanNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};


  const handlePolValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const formattedValue = formatNumber(rawValue);
    const numericValue = rawValue.replace(/\D/g, '');
    
    setFormData({ ...formData, Pws_Val_Pol: numericValue });
    setFormattedPolValue(formattedValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    if (name === 'Pws_Val_Pol' || name === 'Pws_Val_cuoini') {
      return; // These are handled by their specific handlers
    }
    setFormData({ ...formData, [name]: value });
  };

  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formData);
  };

  const mostrarModal = () => {
    setPlan(true); // Cambia el estado de `plan` a `true` para mostrar el Popup
  };

  useEffect(() => {
    getValueListCreditos();
  }, [one])

  const obtenerFechaActual = () => {
    const hoy = new Date();
    const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11
    const dia = String(hoy.getDate()).padStart(2, '0');
    const anio = hoy.getFullYear();

    return `${mes}/${dia}/${anio}`;
}

const getValueListCreditos = async () => {
    const valor = await AdminService.getlineascredito();
    if (valor.payload.data.ListLineaCredito.Datos_LineasCredito.length > 0) {
      const list = valor.payload.data.ListLineaCredito.Datos_LineasCredito.reduce((acc: any[], item: any) => {
          const tipoProducto = sessionStorage.getItem('CodProducto')!;
          if (
              (tipoProducto == '2' && (item.s_codigo == '1' || item.s_codigo == '2')) ||
              (tipoProducto == '1' && (item.s_codigo == '3' || item.s_codigo == '4'))
          ) {
              
              acc.push(item); // Agregamos solo los elementos que cumplen las condiciones
          }
          
          return acc; // Retornamos el acumulador actualizado
      }, []);
      setListcredit(list);
  }
    const valor2 = await AdminService.getPeriodos();
    if (valor2.payload.data.ListPerido.Datos_Periodo.length > 1
        && valor2.payload.data.ListPerido != null && valor2.payload.data.ListPerido!) {
        setPeriodos(valor2.payload.data.ListPerido.Datos_Periodo);
        console.log(valor2);
    } else {
        let periodos = [
            { s_codigo: "1", s_descripcion: "Mensual" },
            { s_codigo: "2", s_descripcion: "Quincenal" },
            { s_codigo: "3", s_descripcion: "Anual" },
            { s_codigo: "4", s_descripcion: "Diaria" },
            { s_codigo: "5", s_descripcion: "Semestral" },
            { s_codigo: "6", s_descripcion: "Trimestral" },
            { s_codigo: "7", s_descripcion: "Bimestral" }
        ]
        setPeriodos(periodos)
    }
}
const handleInitialQuoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const rawValue = event.target.value.replace(/[,.]/g, '');
  setFormData({ ...formData, Pws_Val_cuoini: rawValue });
  //setFormattedInitialQuote(formatNumber(rawValue));
};

  const handleCalculatedQuote = async (e: any) => {
    e.preventDefault();
    setIsSimulator(true);
    let cuotaFormated;
    let cuotaNumberFormated;
    let plazo: string;
    if (formData) {
        if (_.isEmpty(formData.tipoLinea!) || _.isEmpty(formData.Pws_Val_cuoini!) || _.isEmpty(formData.Pws_Identificacion || _.isEmpty(formData.periodicity) || _.isEmpty(formData.Pws_Val_Pol) || _.isEmpty(formData.fullName) || _.isEmpty(formData.email) ) ){
            toast.error('Debe indicar los valores [Opción de Crédito, Valor de la póliza, Valor cuota inicial, Periodicidad,  Identificación, Correo] para poder calcular');
            console.log(formData);
            setIsSimulator(false);
        } else {
            try {
                cuotaFormated = formData.Pws_Val_cuoini.replaceAll(',', '');
                cuotaNumberFormated = formData.periodicity == 'Mensual' ? '12' : formData.periodicity == 'Trimestral' ? '4' : '2';
                if (parseFloat(cuotaFormated) > parseFloat(formData.Pws_Val_Pol)) {
                    toast.error('La cuota inicial debe ser menor al valor de la poliza');
                    return;
                }
               /*  if (parseFloat(cuotaNumberFormated) > 999) {
                    toast.error('El nro de cuota no puede ser mayor a 999');
                } */
                else {
              
                    const valorToFinanciar = String(parseFloat(formData.Pws_Val_Pol!) - parseFloat(cuotaFormated));

                    let infoEnviadaSimulador = {
                        pws_monto: valorToFinanciar!,
                        pws_nro_cuotas: cuotaNumberFormated,
                        pws_fecha_sol: obtenerFechaActual(),
                        pws_cod_credi: formData.tipoLinea!,
                        pws_form_plazo: formData.periodicity,
                        pws_identific: formData.Pws_Identificacion,
                        pws_form_periodic: formData.periodicity,
                        pws_tasa_int: formData.interestRate,
                        pws_tip_domi: formData.plan
                    };
                    console.log("INFO ENVIADA AL SERVICE CYGNUS", infoEnviadaSimulador);

                    let simulador = await WLOCreditAPIService.simulaPoliza(infoEnviadaSimulador);
                    setSimulatorCuotas(simulador.payload.data.R_Liquidacion.LiquidacionAtr);
                    setValueQuote(simulador.payload.data.R_Val_cuota);
                    setvalorVehiculo(simulador.payload.data.R_Val_cuota);
 
                    console.log(simulador.payload.data.R_Liquidacion);
                    if (!_.isEmpty(simulador.payload) && !_.isEmpty(simulador.payload.data)) {
                        setPlan(true);
                        const valorQuote = simulador.payload.data.R_Val_cuota;
                        console.log("VALOR CUOTA!!!", valorQuote)
                     /*    formData.Pws_Val_Pol = valorQuote;
                        formData.Pws_Val_finan = valorToFinanciar; */
                        setValueToFinance(parseFloat(valorToFinanciar));
                        setValueQuote(valorQuote);
                        const infoCredit = simulador;
                        setRequest({
                            ...request,
                            valueOfInicialQuote: formData.Pws_Val_cuoini!,
                            valueOfQuote: valorQuote,
                            valueToFinance: valorToFinanciar.toString(),
                            simulator: infoCredit,
                            isRotativo: Boolean(listCredit.filter((valorflitro: any) => valorflitro.s_codigo == formData.tipoLinea)[0]['s_rotativo'])
                        });
                        toast.success('Se ha generado un nuevo calculo de cuota');
                        handleSendEmail(valorQuote, String(formData.Pws_Val_cuoini!))
                        setIsSimulator(false);
                    }
                }
            } catch (e) {
                setIsSimulator(true);
                toast.error('En este momento no podemos atender su solicitud');
                console.log(e)
                setTimeout(() => {
                    setIsSimulator(false);
                }, 4000);
            }

        }
    }
}
const handleSendEmail = async (valorcuota: string, valorinicial: string) => {
  setIsEmail(true);
  setIsLoadingInfo(true);
  if (_.isEmpty(formData.tipoLinea!) || _.isEmpty(formData.periodicity!) || _.isEmpty(formData.Pws_Val_cuoini!) || _.isEmpty(formData.email!) || _.isEmpty(formData.Pws_Identificacion!)) {
      toast.error('Debe indicar los valores [Correo, Opción de Crédito, Plazo, Valor cuota inicial, Ingrese una cedula correcta, Nro. Cuotas] para poder calcular ');
  } else {
      try {

          const emailInfo = {
              dateSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
              product: request.nameProduct!,
              price: formData.Pws_Val_Pol,
              cuotas: formData.periodicity == 'Mensual' ? '12' : formData.periodicity == 'Trimestral' ? '4' : '2',//formData.pws_nro_cuotas,
              cuotaInicial: valorinicial,
              valorCuota: valorcuota,
              email: formData.email!,
          }
          sessionStorage.setItem('plazo_poliza',formData.periodicity)
          sessionStorage.setItem('id_producto', JSON.stringify(request.nameProduct));
          sessionStorage.setItem('datos_Poliza', JSON.stringify(emailInfo));
          sessionStorage.setItem('idLinea',formData.tipoLinea)
          console.log(emailInfo)
          const resultEnvioCorreo = await WLOCreditAPIService.sendEmailCotizacion(emailInfo);
          if (resultEnvioCorreo) {
              toast.success('Se ha enviado con exito el email con la cotización');
              setIsEmail(false);
              setIsLoadingInfo(false);
          }
      } catch (error) {
          setIsSimulator(true);
          toast.error('En este momento no podemos atender su solicitud');
          setTimeout(() => {
              setIsSimulator(false);
          }, 1000);
      }
  }
}
  return (
    <div className="policies-container">
      <div className="image-container">
        <img src={familia} alt="Persona" />
      </div>
      <div className="form-container">
        <h2>Simula tu crédito</h2>
        <div style={{textAlign: 'left'}}>
          {'La información suministrada corresponde a un valor aproximado de las cuotas del crédito según el monto solicitado y otros parámetros. Una vez tengamos sus datos de referencia, calculamos los valores exactos y no constituyen ningún elemento formal ni contractual, ni tampoco implica el sostenimiento de las condiciones ni el otorgamiento de créditos por parte de FINMERIDIAN.'}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="documentType">Tipo de documento</label>
              <select id="documentType" name="documentType" value={formData.documentType} onChange={handleInputChange}>
                <option value="">Selecciona tipo de documento</option>
                {tiposIdent.map((type:any) => (
                  <option key={type.s_codigo} value={type.s_codigo}>{type.s_descripcion}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="documentNumber">Número de documento</label>
              <input id="documentNumber" name="documentNumber" type="text" value={formData.Pws_Identificacion} onChange={handleInputChange} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="fullName">Nombre</label>
              <input id="fullName" name="fullName" type="text" value={formData.fullName} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input id="email" name="email" type="email" value={formData.email} onChange={handleInputChange} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="tipoLinea">Tipo de Línea</label>
              <select id="tipoLinea" name="tipoLinea" value={formData.tipoLinea} onChange={handleInputChange}>
                <option value="">Selecciona un tipo de línea</option>
                {listCredit.map((type:any) => (
                  <option key={type.s_codigo} value={type.s_codigo}>{type.s_nombre}</option>
                ))}
                
               
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="plan">Plan Financiación</label>
              <select id="plan" name="plan" value={formData.plan} onChange={handleInputChange}>
                <option value="">Selecciona un plan</option>
                <option value="1">DOMICILIACION</option>
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="periodicity">Periodicidad</label>
              <select id="periodicity" name="periodicity" value={formData.periodicity} onChange={handleInputChange}>
              <option value="">Selecciona una periodicidad</option>
              {listPeriodos.map((type:any, i:any) => 
               {
                if(i==0){
                 return (<option key={type.s_descripcion} value={type.s_descripcion}>{type.s_descripcion}</option>)
                }
               }
                )}
                
      {/*         
                <option value="">Selecciona una periodicidad</option>
                <option value="Mensual">Mensual: 12 PAGOS</option>
                <option value="Trismestral">Trismestral: 4 PAGOS</option>
                <option value="Semestral">Semestral: 2 PAGOS</option> */}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="Pws_Val_Pol">Vlr. Póliza</label>
              <input id="Pws_Val_Pol" name="Pws_Val_Pol" type="text" value={formattedPolValue} onChange={handlePolValueChange} />
            </div>
          </div>
          <div className="form-row">
          <input style={{ display: 'none' }} id="Pws_Val_cuoini" name="Pws_Val_cuoini" type="number" value={formattedInitialQuote} onChange={handleInitialQuoteChange} />

         {/*    <div className="form-group">
                <label htmlFor="Pws_Val_cuoini">Cuota Inicial</label>
                <input id="Pws_Val_cuoini" name="Pws_Val_cuoini" type="number" value={formattedInitialQuote} onChange={handleInitialQuoteChange} />
            </div> */}
            <div className="form-group">
              <label htmlFor="interestRate">Tasa de interés (E.A)</label>
              <input id="interestRate" name="interestRate" type="number" value={formData.interestRate} />
            </div>
          </div>
          <div className='boton'>
            <button type="button" onClick={handleCalculatedQuote}>Calcular cuota</button>
          </div>
          {plan && (
            <Popup setfunc={setPlan} Body={<ModalConfirmPolicies cuotas={simulatorCuotas} valorCuota={valueQuote}/>} />
          )}
        </form>
      </div>
    </div>
  );
};